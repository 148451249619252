import React from "react";

import ReactMarkdown from "react-markdown";

import classes from "./AccordionItem.module.css";

const AccordionItem = (props) => {
  const { isOpen, title, content, index, toggleHandler } = props;
  const classArr = [classes.AccordionItem, isOpen ? classes.Open : ""];

  return (
    <div className={classArr.join(" ")} onClick={() => toggleHandler(index)}>
      <h3 className={classes.Question}>
        <ReactMarkdown>{title}</ReactMarkdown>
      </h3>
      <ReactMarkdown className={`${classes.Answer} markdown`}>
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default AccordionItem;
