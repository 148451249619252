import React from "react";
import { useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet";

import * as C from "./";

const Container = () => {
  const content = useLoaderData();

  return (
    <>
      <Helmet>
        <title>Carbonaires</title>
        <meta
          property="og:title"
          content="Carbonaires"
          data-react-helmet="true"
        />
      </Helmet>
      <C.FullSlider data={content.main_full_slider} />
      {/* <C.MainSlider data={content.main_slider_content} /> */}
      <C.NewsMedia
        data={content.news_slider_content}
        content={content.news_slider}
      />
      <C.Intro data={content.intro} />
      <C.FAQ data={content.FAQ_content} content={content.FAQ} />
    </>
  );
};

export default Container;
