import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";

import Wrapper from "./Wrapper";
import PrevButton from "../../shared/Slider/PrevButton";
import NextButton from "../../shared/Slider/NextButton";

import classes from "./ProjectSlider.module.css";
import LinkAll from "../../shared/App/LinkAll";

const responsive = {
  0: {
    items: 1,
  },
  800: {
    items: 2,
  },
  1200: {
    items: 3,
    itemsFit: "contain",
  },
};

const ProjectSlider = (props) => {
  const { header, content } = props;
  const carousel = useRef(null);

  const items = content.map((el) => (
    <div key={el.id} className={classes.Item}>
      <LinkAll link={el.link}>
        <img src={el.img} alt="" />
      </LinkAll>
    </div>
  ));

  const renderDotsItem = ({ isActive }) => {
    const dotClass = [
      classes.Dot,
      isActive ? classes.ActiveDot : classes.InactiveDot,
    ].join(" ");

    return <span className={dotClass} />;
  };

  const slidePrev = (e) => carousel?.current?.slidePrev(e);
  const slideNext = (e) => carousel?.current?.slideNext(e);

  return (
    <Wrapper className={classes.Wrapper}>
      <article className={classes.Container}>
        <h2 className={classes.Header}>{header}</h2>

        <div className={classes.Slider}>
          <PrevButton onClick={slidePrev} />

          <AliceCarousel
            ref={carousel}
            items={items}
            renderDotsItem={renderDotsItem}
            controlsStrategy={"responsive"}
            responsive={responsive}
            keyboardNavigation
            mouseTracking
            disableButtonsControls
            autoHeight
            infinite
          />
          <NextButton onClick={slideNext} />
        </div>
      </article>
    </Wrapper>
  );
};

export default ProjectSlider;
