import React from "react";

import classes from "./Media.module.css";

const Media = (props) => {
  const { header, data } = props;

  const items = data
    ? data.map((el) => (
        <li key={el.id}>
          <a
            className={classes.Link}
            href={el.link}
            target="blank"
            rel="noopener noreferrer"
          >
            <img src={el.img} alt={el.name} />
            <span>{el.name}</span>
          </a>
        </li>
      ))
    : null;

  return (
    <article className={classes.Container}>
      {header && (
        <h2 className={classes.Header}>
          {header}
          <span className={classes.RightArrow}></span>
        </h2>
      )}
      <ul className={classes.List}>{items}</ul>
    </article>
  );
};

export default Media;
