import { createBrowserRouter, Link, Navigate } from "react-router-dom";
import * as Page from "../pages";
import { POLICY_TYPE } from "./lib";
import * as Loader from "./loader";

const router = createBrowserRouter([
  {
    element: <Page.AppLayout />,
    loader: Loader.getAppContent,
    handle: {
      crumb: () => <Link to="/">Home</Link>,
    },
    children: [
      {
        path: "/news-media/all/:cat",
        element: <Page.ArticleItem contentClass="wide-content" />,
        loader: Loader.getAllNews,
        handle: {
          crumb: () => <span>News & Media</span>,
        },
        errorElement: <Navigate to="/" replace />,
      },
      {
        path: "/news-media/:id",
        element: <Page.ArticleItem />,
        loader: Loader.getArticleDetail,
        handle: {
          crumb: (props) => {
            if (props.category) {
              return [
                <span>News & Media</span>,
                // <Link to={`/news-media/all/${category[props.category]}`}>
                //   {props.category}
                // </Link>,
              ];
            }
            return <span>News & Media</span>;
          },
        },
        errorElement: <Navigate to="/" replace />,
      },
      {
        path: "/article/:id",
        element: <Page.ArticleItem />,
        loader: Loader.getArticleDetail,
        handle: {
          crumb: () => <span>News & Media</span>,
        },
        errorElement: <Navigate to="/" replace />,
      },
      {
        path: "/cookie-policy",
        element: <Page.Policy type={POLICY_TYPE.COOKIES} />,
        loader: Loader.getCookiePolicy,
        handle: {
          crumb: () => <span>Cookie Policy</span>,
        },
        errorElement: <Navigate to="/" replace />,
      },
      {
        path: "/privacy-policy",
        element: <Page.Policy type={POLICY_TYPE.PRIVACY} />,
        loader: Loader.getPrivacyPolicy,
        handle: {
          crumb: () => <span>Privacy Policy</span>,
        },
        errorElement: <Navigate to="/" replace />,
      },
      {
        path: "/about",
        element: <Page.About />,
        handle: {
          crumb: () => <span>About</span>,
        },
        errorElement: <Navigate to="/" replace />,
        children: [
          {
            path: "what-we-do",
            loader: Loader.getAboutWWDContent,
            element: <Page.MarkContainer />,
          },
          {
            path: "our-value-proposition",
            loader: Loader.getAboutOurValueContent,
            element: <Page.MarkContainer />,
          },
          {
            path: "our-technology",
            loader: Loader.getAboutOurTechContent,
            element: <Page.MarkContainer />,
          },
          {
            path: "our-science",
            loader: Loader.getAboutOurScience,
            element: <Page.MarkContainer />,
          },
          {
            path: "people",
            loader: Loader.getAboutPeopleContent,
            element: <Page.AboutPeople />,
          },
          {
            path: "team",
            loader: Loader.getAboutPeopleContent,
            element: <Page.AboutPeople />,
          },
          {
            path: "advisory-board",
            loader: Loader.getAboutBoardContent,
            element: <Page.AboutPeople />,
          },
          // {
          //   path: "carbonaires",
          //   loader: Loader.getAboutUsContent,
          //   element: <Page.AboutCompany />,
          // },
          {
            path: "",
            element: <Navigate to="/about/what-we-do" replace />,
          },
        ],
      },
      {
        path: "/contact-us",
        element: <Page.Contact />,
        loader: Loader.getContactContent,
        handle: {
          crumb: () => <span>Contact us</span>,
        },
        errorElement: <Navigate to="/" replace />,
      },
      {
        path: "/LCAW2023",
        element: <Page.Register />,
        loader: Loader.getRegistrationContent,
        handle: {
          crumb: () => <span>Register</span>,
        },
        errorElement: <Navigate to="/" replace />,
      },
      {
        path: "/projects",
        loader: ({ params }) => {
          return params;
        },
        element: <Page.ProjectsRedirect />,
        children: [
          {
            path: ":sublink",
            element: <Page.ProjectsRedirect />,
          },
        ],
      },
      {
        path: "/portfolio",
        element: <Page.Projects />,
        handle: {
          crumb: () => <span>Portfolio</span>,
        },
        errorElement: <Navigate to="/" replace />,
        children: [
          {
            path: "submit",
            loader: Loader.getProjectsSubmitContent,
            element: <Page.ProjectsSubmit />,
          },

          {
            path: "carbon",
            loader: Loader.getProjectsCarbonContent,
            element: <Page.ProjectsCarbon />,
          },
          {
            path: "for-investors",
            loader: Loader.getForInvestorsContent,
            element: <Page.MarkContainer />,
          },
          {
            path: "for-corporates",
            loader: Loader.getForCorporatesContent,
            element: <Page.MarkContainer />,
          },
          {
            path: "for-funds-family-offices-asset-managers",
            loader: Loader.getForOthersContent,
            element: <Page.MarkContainer />,
          },
          {
            path: "",
            loader: Loader.getPortfolioContent,
            element: <Page.MarkContainer />,
          },
        ],
      },
      {
        path: "/",
        loader: Loader.getHomeContent,
        element: <Page.Home />,
        handle: {
          crumb: () => <Link to="/">Home</Link>,
        },
        errorElement: <Page.Error />,
      },
      {
        path: "/*",
        element: <Navigate to="/" replace />,
      },
    ],
    errorElement: <Page.Error style={{ height: "100vh" }} />,
  },
]);

export default router;
