import React from "react";
import { Link } from "react-router-dom";

import classes from "./NewsItem.module.css";

const NewsItem = (props) => {
  const { title = "", img = "", content = "", link = "/", date } = props.data;

  const dateObj = new Date(date);
  const dateString = dateObj.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const timeString = dateObj.toLocaleTimeString("en-GB", {
    timeStyle: "short",
  });

  return (
    <article className={classes.Item}>
      <div className={classes.ItemContent}>
        <Link to={link}>
          <h2 className={classes.Title}>{title}</h2>
        </Link>

        <p className={classes.Desc}>{content}</p>

        <time className={classes.Date} dateTime={date}>{`${dateString}`}</time>
      </div>

      <div className={classes.ImgContent}>
        <Link to={link}>
          <img src={img} alt="" />
        </Link>
      </div>
    </article>
  );
};

export default NewsItem;
