import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import classes from "./MenuItem.module.css";
import classesNav from "./NavItem.module.css";

const MenuItem = (props) => {
  const {
    id,
    link,
    name,
    isSubbed,
    sub_links,
    isExternalLink,
    itemClass = "",
  } = props;
  const [isSubOpen, setIsSubOpen] = useState(false);

  const openSub = () => setIsSubOpen(true);
  const closeSub = () => setIsSubOpen(false);

  const subClass = [classes.SubNav, isSubOpen ? classes.Open : ""].join(" ");
  const activeClass = ({ isActive }) =>
    isActive ? classesNav.ActiveLink : classesNav.Link;
  const subStyle = isSubbed ? { pointerEvents: "none", cursor: "pointer" } : {};
  const downButton = isSubbed ? (
    <span className={classes.DownButton}></span>
  ) : null;
  const externalProps = {
    className: classesNav.Link,
    rel: "noopener noreferrer",
    style: subStyle,
  };

  if (!link.includes("#")) externalProps.target = "_blank";

  const linkItem =
    isExternalLink || link.includes("http") || link.includes("#") ? (
      <a href={link} {...externalProps}>
        {name}
        {downButton}
      </a>
    ) : (
      <NavLink className={activeClass} to={link} style={subStyle}>
        {name}
        {downButton}
      </NavLink>
    );

  const renderSubLinkItem = (isExternalLink, name, link) => {
    if (isExternalLink || link.includes("http"))
      return (
        <a href={link} {...externalProps} style={{}}>
          {name}
        </a>
      );

    return (
      <NavLink className={activeClass} to={link} end>
        {name}
      </NavLink>
    );
  };

  return (
    <li
      key={id}
      className={`${classes.ListItem} ${itemClass}`}
      onMouseOver={openSub}
      onMouseLeave={closeSub}
    >
      {linkItem}
      {isSubbed ? (
        <ul className={subClass} onMouseLeave={closeSub}>
          {sub_links.map((sub) => (
            <li key={sub.id} onMouseEnter={openSub}>
              {renderSubLinkItem(sub.isExternalLink, sub.name, sub.link)}
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default MenuItem;
