import React from "react";
import AliceCarousel from "react-alice-carousel";

import classes from "./FullSlider.module.css";
import Item from "./Item";
import PrevButton from "../../../shared/Slider/PrevButton";
import NextButton from "../../../shared/Slider/NextButton";
import { checkImage, disableScroll, enableScroll } from "../../../../util/lib";

let fullSlideInterval;

const FullSlider = (props) => {
  const carousel = React.useRef(null);
  const [mainIndex, setMainIndex] = React.useState(0);

  const { data } = props;
  const items = data.map((el) => <Item key={el.id} data={el} />);

  const renderDotsItem = ({ isActive }) => {
    const dotClass = [
      classes.Dot,
      isActive ? classes.ActiveDot : classes.InactiveDot,
    ].join(" ");

    return <span className={dotClass} />;
  };

  const slidePrev = (e) => carousel?.current?.slidePrev(e);
  const slideNext = (e) => carousel?.current?.slideNext(e);

  const slideChangeHandler = (e) => {
    enableScroll();
    clearInterval(fullSlideInterval);
    if (e.slide < data.length) setMainIndex(e.slide);
    fullSlideInterval = setInterval(() => {
      setMainIndex((prev) => (data.length <= prev + 1 ? 0 : prev + 1));
    }, 10000);
  };

  const slideChangingHandler = (e) => {
    disableScroll();
  };

  React.useEffect(() => {
    fullSlideInterval = setInterval(() => {
      setMainIndex((prev) => (data.length <= prev + 1 ? 0 : prev + 1));
    }, 10000);

    return () => {
      clearInterval(fullSlideInterval);
    };
  }, [data.length]);

  const containerStyle = {
    backgroundImage: `url(${data[mainIndex].img})`,
  };

  return (
    <div className={classes.Container} style={containerStyle}>
      <PrevButton onClick={slidePrev} />
      <div className={classes.Content}>
        <AliceCarousel
          ref={carousel}
          activeIndex={mainIndex}
          items={items}
          animationType="fadeout"
          mouseTracking
          keyboardNavigation
          renderDotsItem={renderDotsItem}
          disableButtonsControls
          infinite
          onSlideChanged={slideChangeHandler}
          onSlideChange={slideChangingHandler}
          //   autoHeight
        />
      </div>
      <NextButton onClick={slideNext} />
      <div className={classes.PreloadImg}>
        {data.map((el) => (
          <img key={el.id} src={el.img} alt="" />
        ))}
      </div>
    </div>
  );
};

export default FullSlider;
