import React from "react";
import ReactMarkdown from "react-markdown";

import LinkAll from "../../../shared/App/LinkAll";

import classes from "./Item.module.css";

const Item = (props) => {
  const { button, header, text, slug, img, thumbnail } = props.data;

  const link =
    button.link && button.link !== "/"
      ? button.link
      : slug?.data?.attributes?.slug
      ? `/news-media/${slug.data.attributes.slug}`
      : "";

  const thumbImg = thumbnail || img || "";

  return (
    <article className={classes.Item}>
      <div className={classes.HeaderContainer}>
        {thumbImg && <img src={thumbImg} alt="" />}
        {header && (
          <h3 className={classes.Header}>
            <ReactMarkdown>{header}</ReactMarkdown>
          </h3>
        )}
      </div>
      {text && (
        <ReactMarkdown className={`${classes.Text} markdown`}>
          {text}
        </ReactMarkdown>
      )}
      {link && (
        <LinkAll className={classes.Button} link={link}>
          {button.text}
        </LinkAll>
      )}
    </article>
  );
};

export default Item;
