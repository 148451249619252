import React from "react";
import ReactMarkdown from "react-markdown";

import classes from "./Item.module.css";
import LinkAll from "../../../shared/App/LinkAll";

const Item = (props) => {
  const {
    header = "",
    text = "",
    img,
    button, //link, text
    slug,
  } = props.data;

  const link =
    button && button.link && button.link !== "/"
      ? button.link
      : slug?.data?.attributes?.slug
      ? `/article/${slug.data.attributes.slug}`
      : "";

  return (
    <div className={classes.Item}>
      {header && (
        <h2 className={classes.Header}>
          <ReactMarkdown>{header}</ReactMarkdown>
        </h2>
      )}
      {text && (
        <div className={classes.Text}>
          <ReactMarkdown className="markdown">{text}</ReactMarkdown>
        </div>
      )}

      {link && (
        <LinkAll className={classes.Button} link={link}>
          {button.text}
        </LinkAll>
      )}
    </div>
  );
};

export default Item;
