import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLoaderData } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Widget } from "@typeform/embed-react";

import BreadCrumbs from "../shared/Layout/Breadcrumbs";

import classes from "./Container.module.css";

const Container = () => {
  const content = useLoaderData();
  const [header, setHeader] = useState("");

  useEffect(() => {
    if (content) setHeader(content.page_header);
  }, [content, setHeader]);

  return (
    <div className={classes.Container}>
      <Helmet>
        <title>Register | Carbonaires</title>
        <meta
          property="og:title"
          content="Register | Carbonaires"
          data-react-helmet="true"
        />
      </Helmet>

      <section className={classes.SectionHeader}>
        <BreadCrumbs />
        <h2>{header}</h2>
      </section>

      <section className={classes.SectionContent}>
        <div className={classes.SectionContainer}>
          {(content.header || content.content) && (
            <article className={classes.TextContainer}>
              <h3 className={classes.Header}>{content.header}</h3>
              <ReactMarkdown className={`markdown ${classes.Content}`}>
                {content.content}
              </ReactMarkdown>
            </article>
          )}

          {content.typeform_id && (
            <article className={classes.Form}>
              <Widget
                id={content.typeform_id}
                autoResize
                inlineOnMobile
                enableFullscreen
                disableScroll
                onHeightChanged={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              />
            </article>
          )}
        </div>
      </section>
    </div>
  );
};

export default Container;
