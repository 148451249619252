import axios from "axios";
import { POLICY_TYPE } from "./lib";

export const getAppContent = async () => {
  const response = await axios.get("/api/fetchAppContent");
  const { content } = response.data;
  console.log(content);

  if (!content) throw new Error();
  else return content;
};

export const getCookiePolicy = async () => {
  const response = await axios.get(
    `/api/fetchPolicy?type=${POLICY_TYPE.COOKIES}`
  );
  const { content } = response.data;

  if (!content) throw new Error();
  else return content;
};

export const getAllNews = async ({ params }) => {
  console.log(params);

  const response = await axios.get(`/api/fetchNews?cat=${params.cat}`);
  const { content } = response.data;
  console.log(content);
  if (!content) throw new Error();
  else return content;
};

export const getArticleDetail = async ({ params }) => {
  console.log(params);
  const response = await axios.get(`/api/fetchArticle/${params.id}`);
  const { content } = response.data;

  if (!content) throw new Error();
  else return content;
};

export const getPrivacyPolicy = async () => {
  const response = await axios.get(
    `/api/fetchPolicy?type=${POLICY_TYPE.PRIVACY}`
  );
  const { content } = response.data;

  if (!content) throw new Error();
  else return content;
};

export const getHomeContent = async () => {
  const response = await axios.get("/api/fetchHomeContent");
  const { content } = response.data;
  if (!content) throw new Error();
  else return content;
};

export const getAboutUsContent = async () => {
  const response = await axios.get("/api/fetch-about-us");
  const { content } = response.data;

  if (!content) throw new Error();
  else return content;
};

export const getAboutPeopleContent = async () => {
  const response = await axios.get("/api/fetch-about-people");
  const { content } = response.data;

  if (!content) throw new Error();
  else return content;
};

export const getAboutBoardContent = async () => {
  const response = await axios.get("/api/fetch-about-board");
  const { content } = response.data;

  if (!content) throw new Error();
  else return content;
};

export const getProjectsSubmitContent = async () => {
  const response = await axios.get("/api/fetch-project-new");
  const { content } = response.data;

  if (!content) throw new Error();
  else return content;
};

export const getContactContent = async () => {
  const response = await axios.get("/api/fetch-contact");
  const { content } = response.data;

  if (!content) throw new Error();
  else return content;
};

export const getRegistrationContent = async () => {
  const response = await axios.get("/api/fetch-registration");
  const { content } = response.data;

  if (!content) throw new Error();
  else return content;
};

export const getProjectsCarbonContent = async () => {
  const response = await axios.get("/api/fetch-project-carbon");
  const { content } = response.data;

  if (!content) throw new Error();
  else return content;
};

export const getAboutWWDContent = async () => {
  const response = await axios.get("/api/fetch-about-wwd");
  const { content } = response.data;
  if (!content) throw new Error();
  else return content;
};

export const getAboutOurTechContent = async () => {
  const response = await axios.get("/api/fetch-about-our-tech");
  const { content } = response.data;
  if (!content) throw new Error();
  else return content;
};

export const getAboutOurValueContent = async () => {
  const response = await axios.get("/api/fetch-about-our-value");
  const { content } = response.data;
  if (!content) throw new Error();
  else return content;
};

export const getPortfolioContent = async () => {
  const response = await axios.get("/api/fetch-portfolio");
  const { content } = response.data;
  if (!content) throw new Error();
  else return content;
};

export const getAboutOurScience = async () => {
  const response = await axios.get("/api/fetch-portfolio-science");
  const { content } = response.data;
  if (!content) throw new Error();
  else return content;
};

export const getForInvestorsContent = async () => {
  const response = await axios.get("/api/fetch-project-for-investors");
  const { content } = response.data;
  console.log(content);
  if (!content) throw new Error();
  else return content;
};

export const getForCorporatesContent = async () => {
  const response = await axios.get("/api/fetch-project-for-corporates");
  const { content } = response.data;
  console.log(content);
  if (!content) throw new Error();
  else return content;
};

export const getForOthersContent = async () => {
  const response = await axios.get("/api/fetch-project-for-others");
  const { content } = response.data;
  console.log(content);
  if (!content) throw new Error();
  else return content;
};
