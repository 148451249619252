import React from "react";

const CheckIcon = ({ isChecked }) => {
  return isChecked ? (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.20001 9.8001L8.60001 11.4001L11.8 6.6001"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8 17H4.2C2.4328 17 1 15.5672 1 13.8V4.2C1 2.4328 2.4328 1 4.2 1H13.8C15.5672 1 17 2.4328 17 4.2V13.8C17 15.5672 15.5672 17 13.8 17Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  ) : (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8 17H4.2C2.4328 17 1 15.5672 1 13.8V4.2C1 2.4328 2.4328 1 4.2 1H13.8C15.5672 1 17 2.4328 17 4.2V13.8C17 15.5672 15.5672 17 13.8 17Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default CheckIcon;
