import React from "react";

import MenuItem from "./MenuItem";

import closeIcon from "../../../../../assets/close.svg";

import classes from "./MobileMenu.module.css";

const MobileMenu = (props) => {
  const { isOpen, closeMenu, openMenu, data } = props;
  const mainNav = [];
  const otherNav = []; //external links

  data && data.map((el) => (!el.isLeft ? otherNav.push(el) : mainNav.push(el)));

  const mainNavItems = (
    <ul>
      {mainNav.length &&
        mainNav.map((el) => {
          const isSubbed = el.sub_links && el.sub_links.length;
          return (
            <MenuItem
              key={el.id}
              {...el}
              isSubbed={isSubbed}
              closeMenu={closeMenu}
              openMenu={openMenu}
            />
          );
        })}
    </ul>
  );

  const externalNavItems = (
    <ul className={classes.ExternalList}>
      {otherNav.length &&
        otherNav.map((el, i) => {
          const isSubbed = el.sub_links && el.sub_links.length;
          return (
            <MenuItem
              key={el.id}
              {...el}
              isSubbed={isSubbed}
              closeMenu={closeMenu}
              openMenu={openMenu}
              itemClass={i + 1 === otherNav.length ? classes.Button : ""}
              linkClass={classes.ExternalLink}
            />
          );
        })}
    </ul>
  );

  const closeEl = (
    <div className={classes.CloseIcon} onClick={closeMenu}>
      <img src={closeIcon} alt="" />
    </div>
  );

  const containerClass = [
    classes.MobileContainer,
    isOpen ? classes.Open : "",
  ].join(" ");

  return (
    <div className={containerClass}>
      {closeEl}
      {mainNavItems}
      {externalNavItems}
    </div>
  );
};

export default MobileMenu;
