import React from "react";
import { Helmet } from "react-helmet";

const HelmetItems = (props) => {
  const title = props.title ? `${props.title} | Carbonaires` : "Carbonaires";
  const description =
    props.description ||
    "Carbonaires is a values driven carbon asset management company. We invest in high-integrity projects that generate high-quality carbon credits.";
  const img = props.img || "https://carbonaires.com/carbonaires-logo.png";

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="description" content={description} />
      <meta property="og:image" content={img} />
    </Helmet>
  );
};

export default HelmetItems;
