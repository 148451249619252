import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

export const POLICY_TYPE = {
  PRIVACY: "privacy",
  COOKIES: "cookie",
};

export const COOKIE_TYPE = {
  CONSENT: "carbonaires_consent_v2", //consent name
  ANALYTICS_GA: "_ga",
  ANALYTICS_GID: "_gid",
  GA: "ReactGA", //google analytics name // on server type of cookie
};

export const COOKIE_PROPS = {
  path: "/",
  maxAge: 60 * 60 * 24 * 30 * 6, // 6 months
};

export const initializeGA = () => {
  console.log("initializeGA");
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
    events: {
      Pageview: "Pageview",
    },
  };

  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize(process.env.REACT_APP_GA);
  initializeHubSpot();
};

export const initializeHubSpot = () => {
  window.hubSpot = window._hsq || null;
  console.log("initializeHubSpot:", window.hubSpot);
};

export const cookieFunctions = (
  type,
  isSelected,
  removeFunction,
  setCookie
) => {
  const consentObj = {};

  switch (type) {
    case COOKIE_TYPE.GA: {
      if (isSelected) {
        initializeGA();
        consentObj[COOKIE_TYPE.GA] = 1;
      } else {
        removeFunction(COOKIE_TYPE.ANALYTICS_GA);
        removeFunction(COOKIE_TYPE.ANALYTICS_GID);
        consentObj[COOKIE_TYPE.GA] = 0;
      }

      setCookie(COOKIE_TYPE.CONSENT, consentObj, COOKIE_PROPS);

      return null;
    }
    default:
      return null;
  }
};

function preventDefault(e) {
  if (e.cancelable) e.preventDefault();
}

export function disableScroll() {
  document.body.addEventListener("touchmove", preventDefault, {
    passive: false,
  });
}
export function enableScroll() {
  document.body.removeEventListener("touchmove", preventDefault, {
    passive: false,
  });
}
