import React from "react";
import { Link } from "react-router-dom";

import MenuItem from "./MenuItem";

import classes from "./Navbar.module.css";
import classesNav from "./NavItem.module.css";

const Navbar = (props) => {
  const { content, data } = props;

  const mainNav = [];
  const otherNav = []; //external links

  const logoElement = (
    <Link to="/">
      <div className={classes.Logo}>
        <img src={content.img} alt="" />
        <span>{content.text}</span>
      </div>
    </Link>
  );

  data && data.map((el) => (!el.isLeft ? otherNav.push(el) : mainNav.push(el)));

  const mainNavItems = (
    <ul>
      {mainNav.length &&
        mainNav.map((el) => {
          const isSubbed = el.sub_links && el.sub_links.length;
          return <MenuItem key={el.id} {...el} isSubbed={isSubbed} />;
        })}
    </ul>
  );

  const externalNavItems = (
    <ul>
      {otherNav.length &&
        otherNav.map((el, i) => {
          const isSubbed = el.sub_links && el.sub_links.length;
          return (
            <MenuItem
              key={el.id}
              {...el}
              isSubbed={isSubbed}
              itemClass={i + 1 === otherNav.length ? classes.Button : ""}
            />
          );
        })}
    </ul>
  );

  const navClass = [props.className, classesNav.Nav].join(" ");

  return (
    <nav className={navClass}>
      <div className={classes.MainNav}>
        {logoElement}
        {mainNavItems}
      </div>
      {externalNavItems}
    </nav>
  );
};

export default Navbar;
