import React, { useEffect } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";

import Attributes from "../components/Attributes";
import Impact from "../components/Impact";
import MainContent from "../components/MainContent";
import ProjectSlider from "../components/ProjectSlider";

const Carbon = () => {
  const content = useLoaderData();
  const { setHeader } = useOutletContext();

  useEffect(() => {
    if (content) setHeader(content.page_header);
  }, [content, setHeader]);

  return (
    <>
      {(content.header || content.content) && (
        <MainContent header={content.header} content={content.content} />
      )}
      {content.impact && <Impact {...content.impact} />}
      {content.attributes && <Attributes {...content.attributes} />}
      {content.projects && <ProjectSlider {...content.projects} />}
    </>
  );
};

export default Carbon;
