import React from "react";
import ReactMarkdown from "react-markdown";

import LinkAll from "../../../shared/App/LinkAll";

import classes from "./Intro.module.css";

const Intro = (props) => {
  const { button, header, img, text } = props.data;

  return (
    <section className={classes.Section}>
      <div className={classes.Content}>
        {img && <img className={classes.Img} src={img} alt="" />}
        {header && (
          <h3 className={classes.Header}>
            <ReactMarkdown>{header}</ReactMarkdown>
          </h3>
        )}
        {text && (
          <ReactMarkdown className={`${classes.Text} markdown`}>
            {text}
          </ReactMarkdown>
        )}
        {button && (
          <LinkAll className={classes.Button} link={button.link}>
            {button.text}
          </LinkAll>
        )}
      </div>
    </section>
  );
};

export default Intro;
