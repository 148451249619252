import React from "react";
import ReactMarkdown from "react-markdown";

import classes from "./NewsLetter.module.css";

const NewsLetter = (props) => {
  const { img, data } = props;

  return (
    <article className={classes.Container}>
      {img && <img className={classes.Img} src={img} alt="Newsletter" />}
      <div className={classes.TextContainer}>
        {data?.header && (
          <ReactMarkdown className={classes.Header}>
            {data.header}
          </ReactMarkdown>
        )}
        {data.text && (
          <ReactMarkdown className={`${classes.Text} markdown`}>
            {data.text}
          </ReactMarkdown>
        )}
      </div>
    </article>
  );
};

export default NewsLetter;
