import React from "react";
import { motion } from "framer-motion";

import classes from "./Modal.module.css";

const dropIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1.5,
    },
  },
  exit: {
    opacity: 0,
  },
};

const Modal = (props) => {
  const { onClose, children } = props;

  return (
    <motion.div
      onClick={onClose}
      className={classes.Backdrop}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className={classes.Modal}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {children}
        <button onClick={onClose} className={classes.Button}>
          CLOSE
        </button>
      </motion.div>
    </motion.div>
  );
};

export default Modal;
