import React from "react";

import "react-alice-carousel/lib/alice-carousel.css";
import "./Slider.css";
import classes from "./NewsMedia.module.css";

const DotItem = (props) => {
  const { isActive, index } = props;
  const dotRef = React.useRef();

  const dotClass = [
    classes.Dot,
    isActive ? classes.ActiveDot : classes.InactiveDot,
  ].join(" ");

  React.useEffect(() => {
    if (isActive && dotRef.current) {
      if (index === 0) return;

      if (index % 5 !== 0) {
        if ((index + 1) % 5 === 0) {
          dotRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "end",
          });
        }
        return;
      }

      dotRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [index, isActive]);

  return <span ref={dotRef} className={dotClass} />;
};

export default DotItem;
