import React from "react";
import { motion } from "framer-motion";

import LinkAll from "../../shared/App/LinkAll";

import classes from "./Person.module.css";

const Person = (props) => {
  const { description, img, name, title, link, isSelected, onSelect } = props;

  const containerClass = [
    classes.Container,
    isSelected ? classes.Selected : "",
  ].join(" ");

  return (
    <div className={classes.Item}>
      <motion.div
        layoutId={props.id}
        className={containerClass}
        onClick={onSelect}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className={classes.Person}>
          <div
            style={{ backgroundImage: `url(${img})` }}
            title={name}
            className={classes.ImgContainer}
          ></div>
          <p className={classes.Name}>{name}</p>
          <p className={classes.Title}>{title}</p>
          {link && (
            <LinkAll className={classes.Link} link={link}>
              <svg
                width="84"
                height="42"
                viewBox="0 0 84 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_391_23)">
                  <path
                    d="M0.25 10L0.25 32H22.25L22.25 10L0.25 10ZM7.58333 27.4167H4.83333L4.83333 17.3333H7.58333L7.58333 27.4167ZM6.20833 16.171C5.32283 16.171 4.60417 15.4468 4.60417 14.554C4.60417 13.6612 5.32283 12.937 6.20833 12.937C7.09383 12.937 7.8125 13.6612 7.8125 14.554C7.8125 15.4468 7.09475 16.171 6.20833 16.171ZM18.5833 27.4167H15.8333L15.8333 22.2797C15.8333 19.1923 12.1667 19.4261 12.1667 22.2797L12.1667 27.4167L9.41667 27.4167L9.41667 17.3333H12.1667V18.9513C13.4473 16.5808 18.5833 16.4057 18.5833 21.2209V27.4167Z"
                    fill="#1B5E20"
                  />
                </g>
                <path
                  d="M33.19 25H38.15V23.9H34.49V18H33.19V25ZM39.1763 25H40.4763V18H39.1763V25ZM47.2997 18V22.74L43.4397 18H42.3697V25H43.6597V20.26L47.5197 25H48.5897V18H47.2997ZM55.3454 25H56.8654L53.7454 21.13L56.6854 18H55.2254L51.7654 21.59V18H50.4654V25H51.7654V23.21L52.8754 22.07L55.3454 25ZM59.0505 23.91V21.98H62.4305V20.91H59.0505V19.09L62.8605 19.09V18H57.7505V25L63.0005 25V23.91L59.0505 23.91ZM64.4498 25H67.5098C69.7798 25 71.3298 23.6 71.3298 21.5C71.3298 19.4 69.7798 18 67.5098 18H64.4498V25ZM65.7498 23.9V19.1H67.4498C69.0098 19.1 70.0198 20.05 70.0198 21.5C70.0198 22.95 69.0098 23.9 67.4498 23.9H65.7498ZM72.7115 25H74.0115V18H72.7115V25ZM80.8348 18V22.74L76.9748 18H75.9048V25H77.1948V20.26L81.0548 25H82.1248V18H80.8348Z"
                  fill="#1B5E20"
                />
                <defs>
                  <clipPath id="clip0_391_23">
                    <rect
                      width="22"
                      height="22"
                      fill="white"
                      transform="translate(0.25 10)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </LinkAll>
          )}
        </div>
        {isSelected && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 0.4, duration: 0.8 },
            }}
            exit={{ opacity: 0 }}
            className={classes.Desc}
          >
            {description}
          </motion.p>
        )}
      </motion.div>
    </div>
  );
};

export default Person;
