import React from "react";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import ReactMarkdown from "react-markdown";

import classes from "./SocialModal.module.css";

const SocialModal = ({ data }) => {
  const [showModal, setShowModal] = React.useState(true);

  const renderButtons = (buttons = []) => {
    if (!buttons) return <></>;

    return buttons.map((item) => (
      <button
        key={item.link}
        style={{
          backgroundColor: item.bg_color || "#1B5E20",
          color: item.text_color || "white",
        }}
        onClick={() => window.open(item.link, "_blank")}
        className={classes.LinkButton}
      >
        {item.img && <img src={item.img || ""} alt="logo" />}
        {item.button_text}
      </button>
    ));
  };

  if (!data) return <></>;

  return createPortal(
    <AnimatePresence mode="wait">
      {showModal && (
        <motion.dialog
          open
          className={classes.Modal}
          variants={{
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                delay: 5,
                ease: "easeIn",
                duration: 0.6,
              },
            },
            hide: {
              opacity: 0,
              y: 100,
              transition: {
                ease: "easeIn",
                duration: 0.6,
              },
            },
          }}
          initial={"hide"}
          animate={"visible"}
          exit={"hide"}
        >
          <h1 className={classes.Header}>{data.title}</h1>
          <ReactMarkdown className={classes.Content}>
            {data.content}
          </ReactMarkdown>
          {data.close_button && (
            <button
              title={data.close_button}
              className={classes.Close}
              onClick={() => setShowModal(false)}
            >
              {data.close_button}
            </button>
          )}
          <div className={classes.ActionContainer}>
            {renderButtons(data.social_buttons)}
          </div>
        </motion.dialog>
      )}
    </AnimatePresence>,
    document.getElementById("social-modal")
  );
};

export default SocialModal;
