import React, { useState } from "react";

import MobileMenu from "./MobileMenu";

import menuI from "../../../../../assets/menu.svg";
import icon from "../../../../../assets/send.svg";

import classes from "./MobileNavbar.module.css";
import { Link } from "react-router-dom";

const MobileNavbar = (props) => {
  const { data, content } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuIcon = (
    <div className={classes.Icon} onClick={() => setIsMenuOpen(true)}>
      <img src={menuI} alt="" />
    </div>
  );

  const logoElement = (
    <Link to="/">
      <div className={classes.Logo}>
        <img src={content.img} alt="" />
        <span>{content.text}</span>
      </div>
    </Link>
  );

  const sendIcon = (
    <div className={classes.Icon}>{/* <img src={icon} alt="" /> */}</div>
  );

  const navClass = [props.className, classes.Navbar].join(" ");

  return (
    <nav className={navClass}>
      <MobileMenu
        isOpen={isMenuOpen}
        closeMenu={() => setIsMenuOpen(false)}
        openMenu={() => setIsMenuOpen(true)}
        data={data}
      />
      {menuIcon}
      {logoElement}
      {sendIcon}
    </nav>
  );
};

export default MobileNavbar;
