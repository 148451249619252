import React, { useState } from "react";
import Switch from "react-switch";

import classes from "./ConsentItem.module.css";

const ConsentItem = (props) => {
  const { selected, onChange, type } = props;
  const [checked, setChecked] = useState(selected);

  const handleChange = (checked, e, id) => {
    setChecked(checked);
    onChange(id, checked);
  };

  const switchProps = {
    uncheckedIcon: false,
    checkedIcon: false,
    onChange: handleChange,
    checked,
    id: type,
    onColor: "#4CAF50",
    offColor: "#1b5e20",
    onHandleColor: "#C8E6C9",
    offHandleColor: "#C8E6C9",
    width: 56,
    height: 28,
  };

  return (
    <label className={classes.Label} style={checked ? {} : { opacity: 0.25 }}>
      <span>Switch for {props.children}</span>
      <Switch {...switchProps} />
    </label>
  );
};

export default ConsentItem;
