import React from "react";

import classes from "./Impact.module.css";
import Wrapper from "./Wrapper";

const Impact = (props) => {
  const { header, content } = props;

  const items = content.map((el) => (
    <div key={el.id} className={classes.Item}>
      <h3 className={classes.ItemHeader}>{el.header}</h3>
      <div className={classes.TextContainer}>
        <p>{el.text}</p>
        <div className={classes.ImgContainer}>
          {el.img.map((item) => (
            <img className={classes.Img} key={item.id} src={item.img} alt="" />
          ))}
        </div>
      </div>
    </div>
  ));

  return (
    <Wrapper className={classes.Wrapper}>
      <article className={classes.Container}>
        <h2 className={classes.Header}>{header}</h2>
        <div className={classes.ItemContainer}>{items}</div>
      </article>
    </Wrapper>
  );
};

export default Impact;
