import React, { useState } from "react";

import AccordionItem from "./AccordionItem";

const Accordion = (props) => {
  const [items, setItems] = useState(props.data);

  const toggleHandler = (index) => {
    let updatedItems = items.map((item) => {
      item.id === index ? (item.isOpen = !item.isOpen) : (item.isOpen = false);
      console.log(item);
      return item;
    });
    setItems(updatedItems);
  };

  const accordionItems = items.map((item) => (
    <AccordionItem
      key={item.id}
      isOpen={item.isOpen}
      title={item.header}
      content={item.text}
      index={item.id}
      toggleHandler={toggleHandler}
    />
  ));

  return accordionItems;
};

export default Accordion;
