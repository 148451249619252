import React from "react";
import { useLoaderData } from "react-router-dom";

import SimpleLayout from "../shared/Page/SimpleLayout";
import NewsItem from "./components/NewsItem";

const Container = ({ contentClass = "" }) => {
  const {
    title = "",
    content = "",
    otherContent,
    slug,
    date,
    category,
  } = useLoaderData();

  return (
    <SimpleLayout
      title={category === "Other News" ? "Featured Articles" : category}
      contentTitle={title}
      content={content}
      date={date}
      otherContent={otherContent}
      slug={slug}
      Component={NewsItem}
      contentClass={contentClass}
      meta={{ title }}
    />
  );
};

export default Container;
