import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import ContactForm from "./ContactForm";

const FormMailChimp = (props) => {
  const url =
    "https://irfanoglu.us5.list-manage.com/subscribe/post?u=0e86a5d1db628f1e5039d8b81&id=b326462cd7";

  return (
    <article id="contact">
      <MailchimpSubscribe
        url={url}
        render={(subs) => <ContactForm {...props} {...subs} />}
      />
    </article>
  );
};

export default FormMailChimp;
