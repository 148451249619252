import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";

import Item from "./Item";

import "react-alice-carousel/lib/alice-carousel.css";
import classes from "./MainSlider.module.css";
import "./Slider.css";
import { disableScroll, enableScroll } from "../../../../util/lib";

let slideInterval;

const MainSlider = (props) => {
  const [mainIndex, setMainIndex] = useState(0);

  const { data } = props;
  const items = data.map((el) => <Item key={el.id} data={el} />);

  const renderDotsItem = ({ isActive }) => {
    const dotClass = [
      classes.Dot,
      isActive ? classes.ActiveDot : classes.InactiveDot,
    ].join(" ");

    return <span className={dotClass} />;
  };

  useEffect(() => {
    slideInterval = setInterval(() => {
      setMainIndex((prev) => (data.length <= prev + 1 ? 0 : prev + 1));
    }, 10000);

    return () => {
      clearInterval(slideInterval);
    };
  }, [data.length]);

  const slideChangeHandler = (e) => {
    enableScroll();
    clearInterval(slideInterval);
    setMainIndex(e.item);
    slideInterval = setInterval(() => {
      setMainIndex((prev) => (data.length <= prev + 1 ? 0 : prev + 1));
    }, 10000);
  };

  const slideChangingHandler = (e) => {
    disableScroll();
  };

  return (
    <section id="main-section" className={classes.Section}>
      <AliceCarousel
        activeIndex={mainIndex}
        items={items}
        renderDotsItem={renderDotsItem}
        controlsStrategy={"responsive"}
        animationType="fadeout"
        keyboardNavigation
        mouseTracking
        disableButtonsControls
        infinite
        onSlideChanged={slideChangeHandler}
        onSlideChange={slideChangingHandler}
        // autoHeight
      />
    </section>
  );
};

export default MainSlider;
