import React, { useEffect } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { AnimatePresence } from "framer-motion";

import Person from "../components/Person";
import Modal from "../../shared/Modal/Modal";

import classes from "./People.module.css";

const People = () => {
  const content = useLoaderData();
  const { setHeader } = useOutletContext();

  const [selectedPerson, setSelectedPerson] = React.useState(null);

  useEffect(() => {
    if (content) setHeader(content.page_header);
  }, [content, setHeader]);

  const items = content.people.map((el) => (
    <Person
      key={el.id}
      {...el}
      isSelected={false}
      onSelect={() => {
        setSelectedPerson(el);
      }}
    />
  ));

  return (
    <>
      {content.header && <h3 className={classes.Header}>{content.header}</h3>}
      {content.content && (
        <ReactMarkdown className={`markdown ${classes.Content}`}>
          {content.content}
        </ReactMarkdown>
      )}
      <article className={classes.PeopleContainer}>{items}</article>

      <AnimatePresence mode="wait" initial={false}>
        {selectedPerson && (
          <Modal onClose={() => setSelectedPerson(null)}>
            <Person {...selectedPerson} isSelected={true} onSelect={() => {}} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default People;
