import React from "react";
import { useMatches } from "react-router-dom";

import classes from "./Breadcrumbs.module.css";

const BreadCrumbs = () => {
  const matches = useMatches();

  const iconEl = (
    <span className={classes.Icon}>
      <svg
        width="6"
        height="11"
        viewBox="0 0 6 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 9.5L5 5.5L1 1.5"
          stroke="#F4FAF4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );

  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data))
    .map((crumb, index) =>
      Array.isArray(crumb) ? (
        crumb.map((i, j) => (
          <li key={j} className={classes.Item}>
            {iconEl}
            {i}
          </li>
        ))
      ) : (
        <li key={index} className={classes.Item}>
          {index > 0 && iconEl}
          {crumb}
        </li>
      )
    );

  return <ol className={classes.List}>{crumbs}</ol>;
};

export default BreadCrumbs;
