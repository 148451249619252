import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import remarkGfm from "remark-gfm";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import HelmetItems from "../shared/App/HelmetItems";
import BreadCrumbs from "../shared/Layout/Breadcrumbs";

import { metaData } from "./data";

import markdownClasses from "../../util/ReactMardown.module.css";
import classes from "./Container.module.css";

const Container = (props) => {
  const { type } = props;
  const { title, content } = useLoaderData();

  const renderMetaData = (policyType) => {
    const meta = {
      title: metaData[policyType].title,
      description: metaData[policyType].description,
    };

    return <HelmetItems {...meta} />;
  };

  return (
    <div className={classes.Container}>
      {renderMetaData(type)}

      <section className={classes.SectionHeader}>
        <BreadCrumbs />
        <h2>{title}</h2>
      </section>

      <section className={classes.SectionContent}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className={`${markdownClasses.Container} ${classes.Content}`}
        >
          {content}
        </ReactMarkdown>
      </section>
    </div>
  );
};

export default Container;
