import React from "react";
import ReactMarkdown from "react-markdown";

import Wrapper from "./Wrapper";

import classes from "./MainContent.module.css";

const MainContent = (props) => {
  const { header, content } = props;

  return (
    <Wrapper className={classes.Wrapper}>
      <article className={classes.Container}>
        {header && <h3 className={classes.Header}>{header}</h3>}
        {content && (
          <ReactMarkdown className={`markdown ${classes.Content}`}>
            {content}
          </ReactMarkdown>
        )}
      </article>
    </Wrapper> 
  );
};

export default MainContent;
