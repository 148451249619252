import React from "react";

const NextButton = (props) => {
  return (
    <svg
      onClick={props.onClick}
      width="18"
      height="32"
      viewBox="0 0 18 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer", height: "100%" }}
    >
      <path
        d="M0.880247 0.293333C1.27136 -0.0977778 1.90247 -0.0977778 2.29358 0.293333L18.0002 16L2.29358 31.7067C2.09803 31.9022 1.84025 32 1.58691 32C1.33358 32 1.0758 31.9022 0.880247 31.7067C0.489136 31.3156 0.489136 30.6844 0.880247 30.2933L15.1736 16L0.880247 1.70667C0.489136 1.31556 0.489136 0.684444 0.880247 0.293333Z"
        fill="#F4FAF4"
      />
    </svg>
  );
};

export default NextButton;
