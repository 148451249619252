import React from "react";

import Accordion from "./Accordion";

import classes from "./FAQ.module.css";

const FAQ = (props) => {
  const items = props.data;
  const { header } = props.content;

  return (
    <section className={classes.Section}>
      {header && <h2 className={classes.Header}>{header}</h2>}

      {items && items.length && <Accordion data={items} />}
    </section>
  );
};

export default FAQ;
