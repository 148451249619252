import React from "react";
import { Link } from "react-router-dom";

const LinkAll = (props) => {
  if (props.link.includes("http"))
    return (
      <a
        className={props.className}
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    );

  return (
    <Link className={props.className} to={props.link}>
      {props.children}
    </Link>
  );
};

export default LinkAll;
