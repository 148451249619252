import React from "react";

const PrevButton = (props) => {
  return (
    <svg
      onClick={props.onClick}
      width="18"
      height="32"
      viewBox="0 0 18 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer", height: "100%" }}
    >
      <g clipPath="url(#clip0_47_667)">
        <path
          d="M17.1198 31.7067C16.7286 32.0978 16.0975 32.0978 15.7064 31.7067L-0.000246557 16L15.7064 0.293335C15.902 0.0977782 16.1598 -1.0957e-07 16.4131 -8.74228e-08C16.6664 -6.52757e-08 16.9242 0.0977783 17.1198 0.293335C17.5109 0.684446 17.5109 1.31555 17.1198 1.70667L2.82642 16L17.1198 30.2933C17.5109 30.6844 17.5109 31.3156 17.1198 31.7067Z"
          fill="#F4FAF4"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_667">
          <rect
            width="17.4133"
            height="32"
            fill="white"
            transform="translate(17.4131 32) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PrevButton;
