import React from "react";

import classes from "./Placeholder.module.css";

const Placeholder = () => {
  return (
    <h2 className={classes.Message}>
      We couldn't find anything here. Please check back later!
    </h2>
  );
};

export default Placeholder;
