import React from "react";

import HelmetItems from "../App/HelmetItems";
import ShareButton from "../App/ShareButton";
import BreadCrumbs from "../Layout/Breadcrumbs";
import Placeholder from "../Layout/Placeholder";
import Markdown from "./Markdown";

import classes from "./SimpleLayout.module.css";
import Slider from "./Slider";

const SimpleLayout = (props) => {
  const {
    title,
    content,
    Component,
    contentClass,
    otherTitle,
    otherContent,
    slug,
    date,
    contentTitle,
  } = props;
  let mainContent = null;

  let isList = false;

  if (Array.isArray(content)) {
    if (content.length) {
      isList = true;
      mainContent = content.map((el) => <Component key={el.id} data={el} />);
    } else mainContent = <Placeholder />;
  } else {
    mainContent = <Markdown>{content}</Markdown>;
  }

  const otherSection =
    otherContent && otherContent.length ? (
      <Slider
        header={otherTitle}
        content={otherContent.filter((el) => el.slug !== slug)}
      />
    ) : null;

  const dateObj = date ? new Date(date) : null;
  const dateString = dateObj
    ? dateObj.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : "";
  const timeString = dateObj
    ? dateObj.toLocaleTimeString("en-GB", {
        timeStyle: "short",
      })
    : "";

  return (
    <div className={classes.Container}>
      <HelmetItems {...props.meta} />
      <section className={classes.SectionHeader}>
        <BreadCrumbs />
        <h2>{title}</h2>
      </section>
      <section className={`${classes.SectionContent} ${contentClass}`}>
        {!isList && contentTitle && (
          <div className={classes.Header}>
            <h2 className={classes.Title}>{contentTitle}</h2>
            <ShareButton />
          </div>
        )}
        {date && (
          <time
            className={classes.Date}
            dateTime={date}
          >{`${dateString}`}</time>
        )}
        {mainContent}
      </section>
      {otherSection}
    </div>
  );
};

export default SimpleLayout;
