import { POLICY_TYPE } from "../../util/lib";

export const metaData = {
  [POLICY_TYPE.PRIVACY]: {
    title: "Privacy Policy - Carbonaires",
    description: `This site uses cookies to function, and for anonymous analytics. You can
      read the privacy policy here.`,
  },
  [POLICY_TYPE.COOKIES]: {
    title: "Cookie Policy - Carbonaires",
    description: `This site uses cookies to function, and for anonymous analytics. You can
      read the cookie policy here.`,
  },
};
