import React from "react";

import NewsLetter from "./section/NewsLetter";
import Media from "./section/Media";
import Form from "./section/Form/FormMailChimp";
import FooterBar from "./section/FooterBar";

import classes from "./Footer.module.css";

const Footer = (props) => {
  const { data } = props;

  return (
    <footer id="join-us" className={classes.Footer}>
      <section className={classes.SectionContact}>
        <div className={classes.ContactContent}>
          {data.footer_text && (
            <NewsLetter img={data.img || ""} data={data.footer_text} />
          )}
          <Form
            button={data.form_button}
            text={data.form_text.text || ""}
            header={data.form_text.header || ""}
          />
        </div>
      </section>

      <section className={classes.SectionLinks}>
        {data.social_media && (
          <Media header={data.social_header || ""} data={data.social_media} />
        )}
        <FooterBar data={data.footer_links || []} text={data.copyright || ""} />
      </section>
    </footer>
  );
};

export default Footer;
