import React from "react";
import AliceCarousel from "react-alice-carousel";

import Item from "./Item";
import LinkAll from "../../../shared/App/LinkAll";

import "react-alice-carousel/lib/alice-carousel.css";
import "./Slider.css";
import classes from "./NewsMedia.module.css";
import DotItem from "./DotItem";
import PrevButton from "../../../shared/Slider/PrevButton";
import NextButton from "../../../shared/Slider/NextButton";

const responsive = {
  0: {
    items: 1,
  },
  750: {
    items: 2,
  },
  1024: {
    items: 3,
    itemsFit: "contain",
  },
};

const NewsMedia = (props) => {
  const { data, content } = props;
  const carousel = React.useRef(null);

  const sortedItems = data.sort((a, b) => new Date(b.date) - new Date(a.date));
  const items = sortedItems.map((el) => <Item key={el.id} data={el} />);

  const renderDotsItem = ({ isActive, activeIndex }) => {
    return <DotItem isActive={isActive} index={activeIndex} />;
  };

  const slidePrev = (e) => carousel?.current?.slidePrev(e);
  const slideNext = (e) => carousel?.current?.slideNext(e);

  return (
    <section id="news-media" className={classes.Section}>
      <div className={classes.Content}>
        <h2 className={classes.Header}>{content.slider_header}</h2>
        <AliceCarousel
          ref={carousel}
          items={items}
          renderDotsItem={renderDotsItem}
          controlsStrategy={"responsive"}
          responsive={responsive}
          keyboardNavigation
          mouseTracking
          disableButtonsControls
        />
        <div className={classes.ActionContainer}>
          <PrevButton onClick={slidePrev} />
          <NextButton onClick={slideNext} />
        </div>
        {content.main_button &&
          content.main_button.text &&
          content.main_button.link && (
            <div className={classes.ButtonContent}>
              <LinkAll
                className={classes.Button}
                link={content.main_button.link}
              >
                {content.main_button.text}
              </LinkAll>
            </div>
          )}
      </div>
    </section>
  );
};

export default NewsMedia;
