import React from "react";
import { Navigate, useLoaderData } from "react-router-dom";

const Redirect = () => {
  const { sublink = "" } = useLoaderData();

  return (
    <Navigate to={!!sublink ? `/portfolio/${sublink}` : `/portfolio`} replace />
  );
};

export default Redirect;
