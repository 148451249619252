import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./FooterBar.module.css";

const FooterBar = (props) => {
  const { data, text } = props;

  const renderClass = ({ isActive }) =>
    isActive ? classes.ActiveLink : classes.Link;

  const footerLinks = data
    ? data
        .filter((el) => el.isActive)
        .map((el) => (
          <li key={el.id}>
            {!el.isExternalLink && !el.link.includes("http") ? (
              <NavLink className={renderClass} to={el.link} target="_blank">
                {el.name}
              </NavLink>
            ) : (
              <a
                className={classes.Link}
                href={el.link}
                target="blank"
                rel="noopener noreferrer"
              >
                {el.name}
              </a>
            )}
          </li>
        ))
    : null;

  return (
    <article className={classes.Container}>
      {text && <span className={classes.CopyRight}>{text}</span>}
      <ul className={classes.List}>{footerLinks}</ul>
    </article>
  );
};

export default FooterBar;
