import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import classes from "./MenuItem.module.css";

const MenuItem = (props) => {
  const {
    id,
    link,
    name,
    isSubbed,
    sub_links,
    closeMenu,
    isExternalLink,
    itemClass,
    linkClass,
  } = props;
  const [isSubOpen, setIsSubOpen] = useState(false);

  const openSub = () => setIsSubOpen(true);
  const closeSub = () => setIsSubOpen(false);

  const subClass = [classes.SubNav, isSubOpen ? classes.Open : ""].join(" ");
  const subArrowClass = [
    classes.DownButton,
    isSubOpen ? classes.Open : "",
  ].join(" ");

  const subStyle = isSubbed ? { pointerEvents: "none", cursor: "pointer" } : {};

  const externalProps = {
    className: `${classes.Link} ${linkClass}`,
    rel: "noopener noreferrer",
    style: subStyle,
  };

  if (!link.includes("#")) externalProps.target = "_blank";

  const downButton = isSubbed ? <span className={subArrowClass}></span> : null;
  const activeClass = ({ isActive }) =>
    isActive ? classes.ActiveLink : classes.Link;

  const linkItem =
    isExternalLink || link.includes("http") || link.includes("#") ? (
      <a
        href={link}
        {...externalProps}
        onClick={!isSubbed ? closeMenu : isSubOpen ? closeSub : openSub}
      >
        {name}
        {downButton}
      </a>
    ) : (
      <NavLink
        className={activeClass}
        to={link}
        style={subStyle}
        onClick={!isSubbed ? closeMenu : isSubOpen ? closeSub : openSub}
      >
        {name}
        {downButton}
      </NavLink>
    );

  const renderSubLinkItem = (isExternalLink, name, link) => {
    if (isExternalLink || link.includes("http"))
      return (
        <a href={link} {...externalProps} style={{}}>
          {name}
        </a>
      );

    return (
      <NavLink className={activeClass} to={link} onClick={closeMenu} end>
        {name}
      </NavLink>
    );
  };

  return (
    <li
      key={id}
      className={`${classes.ListItem} ${itemClass}`}
      onMouseOver={openSub}
      onMouseLeave={closeSub}
    >
      {linkItem}
      {isSubbed ? (
        <ul className={subClass} onMouseLeave={closeSub}>
          {sub_links.map((sub) => (
            <li key={sub.id} onMouseEnter={openSub}>
              {renderSubLinkItem(sub.isExternalLink, sub.name, sub.link)}
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default MenuItem;
