import React from "react";

import classes from "./Attributes.module.css";
import Wrapper from "./Wrapper";

const Attributes = (props) => {
  const { header, content } = props;

  const items = content.map((el) => (
    <div key={el.id} className={classes.Item}>
      <p>{el.header}</p>
      <p>{el.text}</p>
    </div>
  )); 

  return (
    <Wrapper className={classes.Wrapper}>
      <article className={classes.Container}>
        <h2 className={classes.Header}>{header}</h2>
        <div>{items}</div>
      </article>
    </Wrapper>
  );
};

export default Attributes;
