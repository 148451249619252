import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

import BreadCrumbs from "../shared/Layout/Breadcrumbs";

import classes from "./Container.module.css";

const Container = () => {
  const [header, setHeader] = useState("");

  return (
    <div className={classes.Container}>
      <Helmet>
        <title>Portfolio | Carbonaires</title>
        <meta
          property="og:title"
          content="Portfolio | Carbonaires"
          data-react-helmet="true"
        />
      </Helmet>
      <section className={classes.SectionHeader}>
        <BreadCrumbs />
        <h2>{header}</h2>
      </section>

      <section className={classes.SectionContent}>
        <Outlet context={{ setHeader: setHeader }} />
      </section>
    </div>
  );
};

export default Container;
