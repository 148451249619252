import React from "react";
import { LinkedinShareButton } from "react-share";

import classes from "./ShareButton.module.css";

const ShareButton = ({ title, url }) => {
  console.log("share");

  return (
    <LinkedinShareButton
      title={title}
      url={window.location.href.replace(
        "http://localhost:3000",
        "https://carbonaires.com"
      )}
    >
      <div className={classes.Button}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_434_35)">
            <path
              d="M3.32 2.33332C3.32 3.25399 2.58 3.99999 1.66667 3.99999C0.753333 3.99999 0.0133333 3.25399 0.0133333 2.33332C0.0133333 1.41332 0.753333 0.666656 1.66667 0.666656C2.58 0.666656 3.32 1.41332 3.32 2.33332ZM3.33333 5.33332H0V16H3.33333V5.33332ZM8.65467 5.33332H5.34267V16H8.65533V10.4007C8.65533 7.28732 12.6747 7.03266 12.6747 10.4007V16H16V9.24599C16 3.99266 10.052 4.18399 8.65467 6.76999V5.33332Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_434_35">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <span>Share on LinkedIn</span>
      </div>
    </LinkedinShareButton>
  );
};

export default ShareButton;
